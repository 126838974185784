/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
//import '../styles/reset.css'

/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Grid } from 'theme-ui'
import { Global } from '@emotion/core'
import Header from './header'
import Footer from './footer'
import Epilogue from '../static/webfonts/Epilogue-VariableFont_wght.woff2'
import EpilogueItalic from '../static/webfonts/Epilogue-Italic-VariableFont_wght.woff2'
import Playfair from '../static/webfonts/PlayfairDisplay-VariableFont_wght.woff2'
import PlayfairItalic from '../static/webfonts/PlayfairDisplay-Italic-VariableFont_wght.woff2'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <Box>
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'Epilogue Variable',
            src: `url(${EpilogueItalic}) format('woff2')`,
            fontWeight: '100 900',
            fontStyle: 'italic',
          },
        }}
      />
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'Epilogue Variable',
            src: `url(${Epilogue}) format('woff2')`,
            fontWeight: '100 900',
            fontStyle: 'normal',
          },
        }}
      />
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'Playfair Display Variable',
            src: `url(${PlayfairItalic}) format('woff2')`,
            fontWeight: '400 900',
            fontStyle: 'italic',
          },
        }}
      />
      <Global
        styles={{
          '@font-face': {
            fontFamily: 'Playfair Display Variable',
            src: `url(${Playfair}) format('woff2')`,
            fontWeight: '400 900',
            fontStyle: 'normal',
          },
        }}
      />
      <Header author={data.site.siteMetadata.author} />
      <Box as='main' sx={{}}>
        {children}
      </Box>
      <Footer author={data.site.siteMetadata.author} />
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
